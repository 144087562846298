import Vue from 'vue';
import {
  BootstrapVue,
  IconsPlugin,
  ToastPlugin,
  ModalPlugin,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import * as VueGoogleMaps from 'vue2-google-maps';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

import '@core/scss/vue/libs/vue-select.scss';
import '@core/scss/vue/libs/quill.scss';

// Global Components
import './global-components';

// mixins
import './plugins/mixins';

// 3rd party plugins
import '@axios';
import '@apiInstance';
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// Axios Mock Adapter
import '@/@fake-db/db';

// DevExtreme
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';

// Bootstrap added 03/10/2022
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// import quill editor styles
// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

// SurveyJS added 09/01/2023
// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
import 'survey-core/modern.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import 'survey-core/survey.i18n.js';
import 'survey-creator-core/survey-creator-core.i18n.js';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');
Vue.component('quill-editor', quillEditor);

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAajJ_ge8aY7JZig0Uop_IZpSCudtYazIg',
    libraries: 'places',
    installComponents: true,
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
